import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';

import './index.css';
import './index.css'

import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Home from './pages/home'
import Apply from './pages/apply'
import Admin from './pages/admin';

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/apply" element={<Apply/>} />
      <Route path="/admin" element={<Admin/>} />
    </Routes>
  </Router>,
  document.getElementById('root')
);