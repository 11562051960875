import React from 'react';
import Footer from '../components/Footer';
import RegisterCandidate from '../components/RegisterCandidate';

const Apply = () => {
  return (
    <div>
      <header></header>

      <main className="sm:flex">
        <div className="sm:w-1/6 sm:bg-left sm:bg-no-repeat sm:bg-fixed sm:bg-contain"></div>
        <div className="sm:w-4/6 mx-auto w-full  rounded-3xl">
          <RegisterCandidate />
        </div>
        <div className="sm:w-1/6 sm:bg-right sm:bg-no-repeat sm:bg-fixed sm:bg-contain"></div>
      </main>

      <footer className="h-44 sm:bg-footer sm:bg-cover">
        <Footer />
      </footer>
    </div>
  );
};

export default Apply;
