import React, { useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [showNav, setShowNav] = useState(false);
  const { t, i18n } = useTranslation();
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  const handleLogout = () => {
    localStorage.clear();
    window.location.pathname = '/';
  };

  return (
  <nav className=" border-gray-200 px-2 sm:px-4 py-2.5 rounded text-white ">
    <div className="container flex flex-wrap justify-between items-center mx-auto">
      <a href="/#home" className="flex items-center">
          {/* <img src="/docs/images/logo.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" /> */}
          <span className="self-center text-xl font-semibold whitespace-nowrap ">AbsoluteYOU</span>
      </a>
      <button onClick={() => setShowNav(!showNav)} type="button" className="inline-flex items-center p-2 ml-3 text-md text-gray-500 rounded-lg md:hidden  focus:outline-none  ">
        <span className="sr-only">Open main menu</span>
        <svg className="w-6 h-6" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      </button>
      <div className={(showNav ? '' : 'hidden') + " w-full md:block md:w-auto"} id="mobile-menu">
        <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-md">
          <li>
            <a href="/#home" className="block py-2 pr-4 pl-3 text-white  rounded md:bg-transparent  md:hover:text-gray-900 md:p-0 ">
              {t('header.home')}
            </a>
          </li>
          <li>
            <a href="/#about" className="block py-2 pr-4 pl-3 text-white  border-gray-100  md:hover:bg-transparent md:border-0 md:hover:text-gray-900 md:p-0  ">
              {t('header.info')}
            </a>
          </li>
          <li>
            <a href="/#register-customer" className="block py-2 pr-4 pl-3 text-white border-b border-gray-100  md:hover:bg-transparent md:border-0 md:hover:text-gray-900 md:p-0  ">
            {t('header.start')}
            </a>
          </li>
          <li>
            {!isAuthenticated ? (
            <a
              href="/admin"
              className='block py-2 pr-4 pl-3 text-white border-b border-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 md:p-0  '
            >
              {t('header.login')}
            </a>
          ) : (
            <a
              href=""
              onClick={() => handleLogout()}
              className='block py-2 pr-4 pl-3 text-white border-b border-gray-100  md:hover:bg-transparent md:border-0 md:hover:text-gray-900 md:p-0 '
            >
              {t('header.logout')}
            </a>
          )}
          </li>
          <li>
            <div className="block py-2 pr-4 pl-3 text-white border-b border-gray-100 md:border-l md:border-gray-100 md:hover:bg-transparent md:border-0  md:p-0  ">
              {i18n.language === 'fr' ? (
              <span className="cursor-pointer md:ml-4 md:hover:text-gray-900" onClick={() => i18n.changeLanguage('nl')}>
                NL
              </span>
              ) : (
              <span className="cursor-pointer md:ml-4 md:hover:text-gray-900" onClick={() => i18n.changeLanguage('fr')}>
                FR
              </span>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  );
};

export default Header;
