import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Step4(props) {
  const { t } = useTranslation();
  return (
        <form onSubmit={props.handleSubmit(props.onSubmit)} id="content-form">
          <div className="lg:w-2/3 md:w-2/3 mx-auto">
            <div className="flex flex-wrap -m-2">
              <h3 className="w-full my-6 sm:text-2xl text-xl  font-medium title-font mb-4 text-gray-900">
              {t('registration.step4.title')}
              </h3>
              <div className="p-2 sm:w-1/2">
                <div className="relative">
                  <label htmlFor="taxid" className="leading-7 text-sm text-gray-600">
                  {t('registration.step4.taxid-field-label')}
                  </label>
                  <input
                    {...props.register('taxid', { required: t('registration.step4.field-validation'), maxLength: 40 })}
                    type="text"
                    name="taxid"
                    className="w-full  bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  {props.errors.taxid && <span className="text-red-600">{props.errors.taxid.message}</span>}
                </div>
              </div>
              <div className="p-2 sm:w-1/2">
                <div className="relative">
                  <label htmlFor="contactPersonName" className="leading-7 text-sm text-gray-600">
                  {t('registration.step4.name-field-label')}
                  </label>
                  <input
                    {...props.register('contactPersonName', { required: t('registration.step4.field-validation'), maxLength: 40 })}
                    type="text"
                    name="contactPersonName"
                    className="w-full  bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  {props.errors.contactPersonName && <span className="text-red-600">{props.errors.contactPersonName.message}</span>}
                </div>
              </div>
              <div className="p-2 sm:w-1/2">
                <div className="relative">
                  <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                  {t('registration.step4.telephone-field-label')}
                  </label>
                  <input
                    {...props.register('contactPersonPhone', { required: t('registration.step4.field-validation'), maxLength: 40 })}
                    type="text"
                    name="contactPersonPhone"
                    className="w-full bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  {props.errors.contactPersonPhone && (
                    <span className="text-red-600">{props.errors.contactPersonPhone.message}</span>
                  )}
                </div>
              </div>
              <div className="p-2 sm:w-1/2">
                <div className="relative">
                  <label htmlFor="email" className="leading-7 text-sm text-gray-600 ">
                  {t('registration.step4.email-field-label')}
                  </label>
                  <input
                    {...props.register('contactPersonEmail', { required: t('registration.step4.field-validation'), pattern: /^\S+@\S+$/i })}
                    type="email"
                    name="contactPersonEmail"
                    className="w-full  bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  {props.errors.contactPersonEmail && (
                    <span className="text-red-600">{props.errors.contactPersonEmail.message}</span>
                  )}
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label htmlFor="message" className="leading-7 text-sm text-gray-600">
                  {t('registration.step4.message-field-label')}
                  </label>
                  <textarea
                    {...props.register('message')}
                    name="message"
                    className="w-full  bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </form>
  );
}
