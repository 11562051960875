import { useEffect } from 'react';

import Intro from '../components/Intro';
import About from '../components/About';
import Header from '../components/Header';

import Footer from '../components/Footer';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import RegisterWizard from '../components/Registration/RegisterWizard';

const Home = () => {
  localStorage.clear();
  useEffect(() => {
    AOS.init();
  });

  return (
    <div className="">
      <header className="sm:w-4/6 sm:mx-auto sm:rounded-2xl sm:sticky sm:top-0 sm:z-50 bg-[#7761a7]">
        <Header />
      </header>
      <main className='sm:flex'>
        <div className='sm:w-1/6 sm:bg-left sm:bg-no-repeat sm:bg-fixed sm:bg-contain'></div>
        <div className="sm:w-4/6 mx-auto w-full  rounded-3xl">
          <Intro />
          <About />
          <RegisterWizard />
        </div>
        <div className='sm:w-1/6 sm:bg-right sm:bg-no-repeat sm:bg-fixed sm:bg-contain'></div>
      </main>

      <footer className='h-44 sm:bg-footer sm:bg-cover'>
        <Footer />
      </footer>
    </div>
  );
};

export default Home;
