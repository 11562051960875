import React from 'react';
import { useTranslation } from 'react-i18next';
const Loader = require('react-loader-spinner').ThreeDots;

export default function Step3(props) {
  const { t } = useTranslation();
  return props.isLoading ? (
    <div>
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 text-center">{t('registration.step3.loading')}</h1>
      <div className="transform translate-x-1/2">
        <Loader />
      </div>
    </div>
  ) : (
    <form onSubmit={props.handleSubmit(props.onSubmit)} id="content-form">
      {
        props.errorMessage !== '' ? (
          <div className="lg:w-2/3 md:w-2/3 mx-auto text-center">
            <p>{props.errorMessage}</p>
          </div>
        ) : (
          <div>
          <div className="lg:w-2/3 md:w-2/3 mx-auto">
            <img src={props.preview} alt="flyerPreview" />
          </div>
        </div>
        )
      }

    </form>
  );
}
