import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <section className="text-gray-600 body-font" id="about">
      <div className="container px-5 py-6 sm:py-24 mx-auto flex flex-wrap ">
        <div className="flex flex-wrap w-full" data-aos="fade-up-right" data-aos-mirror="true">
          <div className="w-full md:w-1/2 text-center sm:text-left">
            <h3 className="text-[#7761a7]  text-2xl tracking-tight sm:text-3xl md:text-2xl lg:text-3xl xl:text-4xl mb-4">
              {t('about.title')}
            </h3>
            <p className="md:text-base text-gray-500 my-3 text-sm">{t('about.paragraph1')}</p>
            <p className="md:text-base text-gray-500 my-3">{t('about.paragraph2')}</p>
            <p className="md:text-base text-gray-500 my-3">{t('about.paragraph3')}</p>
            <p className="md:text-base text-gray-500 my-3">{t('about.paragraph4')}</p>
            <p className="md:text-base text-gray-500 my-3">{t('about.paragraph5')}</p>
            <p className="md:text-base text-gray-500 my-3">{t('about.paragraph6')}</p>
          </div>

          <div className="md:w-1/2 md:pl-10 py-6 my-auto" data-aos="fade-up-left" data-aos-mirror="true">
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#7761a7] inline-flex items-center justify-center text-white relative z-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 3H3v7h18V3z" />
                  <path d="M21 14h-5v7h5v-7z" />
                  <path d="M12 14H3v7h9v-7z" />
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  {t('about.step1-title')}
                </h2>
                <p className="leading-relaxed">{t('about.step1-decription')}</p>
              </div>
            </div>
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#7761a7] inline-flex items-center justify-center text-white relative z-10">
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="🔍-Product-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="ic_fluent_edit_24_regular" fill="#ffffff" fillRule="nonzero">
                      <path
                        d="M21.0303301,2.96966991 C22.4277928,4.36713263 22.4277928,6.63286737 21.0303301,8.03033009 L9.06200371,19.9986565 C8.78512854,20.2755316 8.44079112,20.4753584 8.06302761,20.5783848 L2.94733805,21.9735729 C2.38746387,22.1262658 1.87373417,21.6125361 2.02642713,21.0526619 L3.4216152,15.9369724 C3.52464161,15.5592089 3.72446837,15.2148715 4.00134354,14.9379963 L15.9696699,2.96966991 C17.3671326,1.5722072 19.6328674,1.5722072 21.0303301,2.96966991 Z M15.0001717,6.06057288 L5.06200371,15.9986565 C4.96971199,16.0909482 4.90310306,16.2057273 4.86876093,16.3316485 L3.81891446,20.1810855 L7.6683515,19.1312391 C7.79427267,19.0968969 7.90905181,19.030288 8.00134354,18.9379963 L17.9391717,8.99957288 L15.0001717,6.06057288 Z M17.0303301,4.03033009 L16.0601717,4.99957288 L18.9991717,7.93957288 L19.9696699,6.96966991 C20.7813462,6.15799363 20.7813462,4.84200637 19.9696699,4.03033009 C19.1579936,3.21865381 17.8420064,3.21865381 17.0303301,4.03033009 Z"
                        id="🎨-Color"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  {t('about.step2-title')}
                </h2>
                <p className="leading-relaxed">{t('about.step2-decription')}</p>
              </div>
            </div>
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#7761a7] inline-flex items-center justify-center text-white relative z-10">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 306.608 306.608"
                >
                  <g fill="#ffffff" fillRule="nonzero">
                    <path
                      d="M165.497,182.548c-14.314-3.073-27.379-10.198-37.784-20.602c-14.385-14.385-22.307-33.51-22.307-53.853
		s7.922-39.468,22.307-53.853c29.696-29.694,78.012-29.694,107.707,0c21.25,21.251,28.024,53.118,17.255,81.185
		c-1.483,3.867,0.449,8.205,4.316,9.689c3.868,1.481,8.205-0.449,9.689-4.316c12.888-33.591,4.78-71.73-20.654-97.164
		c-17.218-17.218-40.11-26.7-64.459-26.7c-24.35,0-47.242,9.482-64.46,26.7s-26.7,40.11-26.7,64.459
		c0,21.831,7.643,42.476,21.628,58.925l-19.83,19.831l-11.916-11.916c-2.929-2.929-7.678-2.929-10.606,0L9.243,235.372
		c-12.324,12.324-12.324,32.376,0,44.701l0.344,0.344c5.97,5.97,13.908,9.258,22.351,9.258s16.38-3.288,22.35-9.258l34.728-34.728
		c2.929-2.929,2.929-7.678,0-10.606c-2.929-2.929-7.678-2.929-10.606,0L43.681,269.81c-3.137,3.137-7.308,4.864-11.744,4.864
		c-4.437,0-8.607-1.728-11.744-4.864l-0.344-0.344c-6.475-6.476-6.475-17.012,0-23.488l55.136-55.136l29.135,29.135
		c2.929,2.929,7.678,2.929,10.606,0c2.929-2.929,2.929-7.678,0-10.606l-11.916-11.916l19.824-19.824
		c11.426,9.699,25.015,16.427,39.713,19.583c0.531,0.114,1.061,0.168,1.582,0.168c3.459,0,6.57-2.408,7.326-5.927
		C172.125,187.406,169.547,183.418,165.497,182.548z"
                    />
                    <path
                      d="M242.285,154.45c-35.468,0-64.323,28.855-64.323,64.323s28.855,64.323,64.323,64.323s64.323-28.855,64.323-64.323
		S277.753,154.45,242.285,154.45z M242.285,268.096c-27.197,0-49.323-22.126-49.323-49.323s22.126-49.323,49.323-49.323
		s49.323,22.126,49.323,49.323S269.482,268.096,242.285,268.096z"
                    />
                    <path
                      d="M260.515,197.682l-26.273,26.273l-10.187-10.187c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.606
		l15.49,15.49c1.464,1.464,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197l31.577-31.577c2.929-2.929,2.929-7.678,0-10.606
		C268.193,194.753,263.444,194.753,260.515,197.682z"
                    />
                  </g>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  {t('about.step3-title')}
                </h2>
                <p className="leading-relaxed">{t('about.step3-decription')}</p>
              </div>
            </div>
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#7761a7] inline-flex items-center justify-center text-white relative z-10">
                <svg
                  version="1.1"
                  id="Uploaded to svgrepo.com"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="32px"
                  height="32px"
                  viewBox="0 0 32 32"
                >
                  <g fill="#ffffff" fillRule="nonzero">
                    <path
                      d="M27,10c0.552,0,1-0.448,1-1V8c0-0.552-0.448-1-1-1h-1V6c0-1.657-1.343-3-3-3H9C7.343,3,6,4.343,6,6v2
	H4.5C4.224,8,4,8.224,4,8.5C4,8.776,4.224,9,4.5,9H6v1H4.5C4.224,10,4,10.224,4,10.5C4,10.776,4.224,11,4.5,11H6v10H4.5
	C4.224,21,4,21.224,4,21.5C4,21.776,4.224,22,4.5,22H6v1H4.5C4.224,23,4,23.224,4,23.5C4,23.776,4.224,24,4.5,24H6v2
	c0,1.657,1.343,3,3,3h14c1.657,0,3-1.343,3-3v-4h1c0.552,0,1-0.448,1-1v-1c0-0.552-0.448-1-1-1h-1v-1h1c0.552,0,1-0.448,1-1v-1
	c0-0.552-0.448-1-1-1h-1v-1h1c0.552,0,1-0.448,1-1v-1c0-0.552-0.448-1-1-1h-1v-1H27z M26,8h1v1h-1V8z M25,26c0,1.105-0.895,2-2,2H9
	c-1.105,0-2-0.895-2-2V6c0-1.105,0.895-2,2-2h14c1.105,0,2,0.895,2,2V26z M27,20v1h-1v-1H27z M27,16v1h-1v-1H27z M27,12v1h-1v-1H27z
	 M18.17,16.062c0.639-0.672,0.977-1.635,0.768-2.676c-0.236-1.179-1.215-2.134-2.399-2.34C14.645,10.717,13,12.167,13,14
	c0,0.801,0.319,1.524,0.83,2.062c-1.122,0.56-1.83,1.706-1.83,2.959V21c0,0.552,0.448,1,1,1h6c0.552,0,1-0.448,1-1v-1.978
	C20,17.768,19.291,16.622,18.17,16.062z M16,12c1.103,0,2,0.897,2,2c0,1.103-0.897,2-2,2s-2-0.897-2-2C14,12.897,14.897,12,16,12z
	 M19,21h-6v-1.585c0-0.986,0.48-1.903,1.288-2.464l0.463-0.231C15.132,16.896,15.553,17,16,17s0.868-0.104,1.249-0.28l0.463,0.231
	C18.52,17.512,19,18.429,19,19.415V21z"
                    />
                  </g>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  {t('about.step4-title')}
                </h2>
                <p className="leading-relaxed">{t('about.step4-decription')}</p>
              </div>
            </div>
            <div className="flex relative">
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#7761a7] inline-flex items-center justify-center text-white relative z-10">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                  {t('about.step5-title')}
                </h2>
                <p className="leading-relaxed">{t('about.step5-decription')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
