import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

function EditCustomer(props) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();


  const onSubmit = (data) => {
    let formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    axios
      .put(`/api/jobs/${props.jobData.id}`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then(() => {
        props.onClose();
      });
  };

  return (
    <div className="container px-5 py-24 mx-auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="lg:w-2/3 md:w-2/3 mx-auto">
          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                  Naam
                </label>
                <input
                  {...register('companyName', { required: 'Dit is verplicht', maxLength: 40 })}
                  type="text"
                  name="companyName"
                  defaultValue={props.jobData.companyName}
                  className="w-full bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {errors.companyName && <span className="bg-yellow-400">{errors.companyName.message}</span>}
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                  Plaats tewerkstelling
                </label>
                <input
                  {...register('companyRegion', { required: 'Dit is verplicht', maxLength: 40 })}
                  type="text"
                  name="companyRegion"
                  defaultValue={props.jobData.companyRegion}
                  className="w-full bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {errors.companyRegion && <span className="bg-yellow-400">{errors.companyRegion.message}</span>}
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="taxid" className="leading-7 text-sm text-gray-600">
                  Btw-nummer
                </label>
                <input
                  {...register('taxid', { required: 'Dit is verplicht', maxLength: 40 })}
                  type="text"
                  name="taxid"
                  defaultValue={props.jobData.taxid}
                  className="w-full  bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {errors.taxid && <span className="bg-yellow-400">{errors.taxid.message}</span>}
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="contactPersonName" className="leading-7 text-sm text-gray-600">
                  Naam Contactpersoon
                </label>
                <input
                  {...register('contactPersonName', { required: 'Dit is verplicht', maxLength: 40 })}
                  type="text"
                  id="contactPersonName"
                  name="contactPersonName"
                  defaultValue={props.jobData.contactPersonName}
                  className="w-full  bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {errors.contactPersonName && <span className="bg-yellow-400">{errors.contactPersonName.message}</span>}
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                  Telefoon Contactpersoon
                </label>
                <input
                  {...register('contactPersonPhone', { required: 'Dit is verplicht', maxLength: 40 })}
                  type="text"
                  name="contactPersonPhone"
                  defaultValue={props.jobData.contactPersonPhone}
                  className="w-full bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {errors.contactPersonPhone && (
                  <span className="bg-yellow-400">{errors.contactPersonPhone.message}</span>
                )}
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                  Email Contactpersoon
                </label>
                <input
                  {...register('contactPersonEmail', { required: 'Dit is verplicht', pattern: /^\S+@\S+$/i })}
                  type="email"
                  id="contactPersonEmail"
                  name="contactPersonEmail"
                  defaultValue={props.jobData.contactPersonEmail}
                  className="w-full  bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {errors.contactPersonEmail && (
                  <span className="bg-yellow-400">{errors.contactPersonEmail.message}</span>
                )}
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="file">Laad nieuw logo op</label>
                <input {...register('file')} className="p-3 mt-2 mb-2 border-none" type="file" name="file" />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <div className="w-full">
                  <label htmlFor="candidateTypes" className="leading-7 text-sm text-gray-600">
                    Ik ben op zoek naar
                  </label>
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/3 sm:w-1/2">
                      <input
                        {...register('candidateTypes', { required: 'Kies minstens één jobtype' })}
                        className="p-3 mt-2 mb-2 ml-3 border-none"
                        type="checkbox"
                        name="candidateTypes"
                        value="flexijob"
                        defaultChecked={props.jobData.jobCandidateFlags.includes('f')}
                      />{' '}
                      Flexijobs
                    </div>
                    <div className="w-full md:w-1/3 sm:w-1/2">
                      <input
                        {...register('candidateTypes', { required: 'Kies minstens één jobtype' })}
                        className="p-3  mt-2 mb-2 ml-3 border-none"
                        type="checkbox"
                        name="candidateTypes"
                        value="student"
                        defaultChecked={props.jobData.jobCandidateFlags.includes('s')}
                      />{' '}
                      Studenten
                    </div>
                    <div className="w-full md:w-1/3 sm:w-1/2">
                      <input
                        {...register('candidateTypes', { required: 'Kies minstens één jobtype' })}
                        className="p-3  mt-2 mb-2 ml-3 border-none"
                        type="checkbox"
                        name="candidateTypes"
                        value="extra"
                        defaultChecked={props.jobData.jobCandidateFlags.includes('e')}
                      />{' '}
                      Extra's
                    </div>
                  </div>
                </div>
                {errors.candidateTypes && <span className="bg-yellow-400">{errors.candidateTypes.message}</span>}
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <div className="w-full">
                  <label htmlFor="jobPeriod" className="leading-7 text-sm text-gray-600">
                    Voor de periode
                  </label>
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/3 sm:w-1/2">
                      <input
                        {...register('jobPeriod', { required: 'Kies minstens één job periode' })}
                        className="p-3 mt-2 mb-2 ml-3 border-none"
                        type="checkbox"
                        name="jobPeriod"
                        value="voorjaar"
                        defaultChecked={props.jobData.jobPeriod.includes('v')}
                      />{' '}
                      Voor- en Najaar
                    </div>
                    <div className="w-full md:w-1/3 sm:w-1/2">
                      <input
                        {...register('jobPeriod', { required: 'Kies minstens één job periode' })}
                        className="p-3  mt-2 mb-2 ml-3 border-none"
                        type="checkbox"
                        name="jobPeriod"
                        value="zomer"
                        defaultChecked={props.jobData.jobPeriod.includes('z')}
                      />{' '}
                      Juli/Augustus 2022
                    </div>
                    <div className="w-full lg:w-1/3 sm:w-1/2">
                      <input
                        {...register('jobPeriod', { required: 'Kies minstens één job periode' })}
                        className="p-3  mt-2 mb-2 ml-3 border-none"
                        type="checkbox"
                        name="jobPeriod"
                        value="weekends"
                        defaultChecked={props.jobData.jobPeriod.includes('w')}
                      />{' '}
                      Weekends
                    </div>
                  </div>
                </div>
                {errors.jobPeriod && <span className="text-red-600">{errors.jobPeriod.message}</span>}
              </div>
            </div>
            <div className="p-2 w-full flex">
              <button
                className="flex-1 m-5  text-white bg-[#7761a7] border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                type="submit"
              >
                Opslaan
              </button>
              <button
                className="flex-1 m-5 text-white bg-[#7761a7] border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                onClick={props.onClose}
              >
                Sluit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
export default EditCustomer;
