import React from 'react';
import { useTranslation } from 'react-i18next';
const Loader = require('react-loader-spinner').ThreeDots;

export default function Step5(props) {
  const { t } = useTranslation();

  return props.isLoading ? (
    <div>
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 text-center">
        {t('registration.step5.loading')}
      </h1>
      <div className="transform translate-x-1/2">
        <Loader />
      </div>
    </div>
  ) : (
    <div className="mx-auto">
      {props.errorMessage !== '' ? (
        <div className="lg:w-2/3 md:w-2/3">
          <p>{props.errorMessage}</p>
        </div>
      ) : (
        <>
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 text-center">Succes! 🎉</h1>
          <p className="text-center leading-relaxed text-base">{t('registration.step5.success')}</p>
        </>
      )}
    </div>
  );
}
