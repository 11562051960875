import Header from '../components/Header';
import JobList from '../components/JobList';
import LoginComponent from '../components/Login';

const Login = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  
  return (
    <div id="loginRoot">
      <header className="sm:w-4/6 sm:mx-auto sm:rounded-2xl sm:sticky sm:top-0 sm:z-50 bg-[#7761a7]">
        <Header />
      </header>
      <main className="sm:w-4/5 mx-auto">
        { !isAuthenticated ? <LoginComponent /> : <JobList />}
      </main>

      <footer></footer>
    </div>
  );
};

export default Login;
