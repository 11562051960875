import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import axios from 'axios';
const Loader = require('react-loader-spinner').ThreeDots;

export default function RegisterCandidate() {
  const { t, i18n } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [availableJobs, setAvailableJobs] = useState(null);

  useEffect(() => {
    axios.get('/api/jobs').then((res) => {
      const jobs = JSON.parse(res.data || []);
      const orderedJobs = jobs.sort((a, b) => (a.companyRegion.toLowerCase() > b.companyRegion.toLowerCase() ? 1 : -1));
      const mappedJobs = orderedJobs.map((c) => ({
        label: `${c.companyName} (${c.companyRegion})`,
        value: c.id,
        group: c.companyRegion,
      }));
      setAvailableJobs(mappedJobs);
    });
  }, []);

  const onSubmit = (data) => {
    setFormSubmitted(true);
    setIsLoading(true);
    let formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key === 'jobSelection') {
        const mappedJobSelection = value.map((c) => c.value);
        formData.append(key, mappedJobSelection);
        continue;
      }
      formData.append(key, value);
    }

    if (Array.from(data.file).length > 0) {
      formData.set('file', Array.from(data.file)['0']);
    } else {
      formData.delete('file');
    }

    formData.set('lang', i18n.language);

    axios
      .post('/api/jobs/apply', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <section className="text-gray-600 body-font relative" id="register-customer">
        <div className="container justify-end flex text-lg content-end">
          {i18n.language === 'fr' ? (
            <div className="cursor-pointer mr-8 md:hover:text-gray-900 text-md pt-4" >
              <span onClick={() => i18n.changeLanguage('nl')}>NL</span>
            </div>
          ) : (
            <div className="cursor-pointer mr-8 md:hover:text-gray-900 text-md pt-4">
              <span onClick={() => i18n.changeLanguage('fr')}>FR</span>
            </div>
          )}
        </div>
        <div className="container px-5 py-24 mx-auto">
          {formSubmitted ? (
            isLoading ? (
              <div>
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 text-center">
                  {t('candidate.loading')}
                </h1>
                <div className="transform translate-x-1/2">
                  <Loader />
                </div>
              </div>
            ) : (
              <div className="w-full mx-auto">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 text-center">
                  Succes! 🎉
                </h1>
                <p className="text-center leading-relaxed text-base">{t('candidate.success')}</p>
              </div>
            )
          ) : (
            <>
              <div className="flex flex-col text-center w-full mb-12">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                  {t('candidate.title')}
                </h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="lg:w-2/3 md:w-2/3 mx-auto">
                  <div className="flex flex-wrap -m-2">
                    <div className="p-2  sm:w-1/2 w-full">
                      <div className="relative">
                        <label htmlFor="firstname" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.firstname-field-label')}
                        </label>
                        <input
                          {...register('firstname', { required: t('candidate.field-validation'), maxLength: 80 })}
                          type="text"
                          name="firstname"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.firstname && (
                          <span className="text-red-600 text-center">{errors.firstname.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="relative">
                        <label htmlFor="lastname" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.name-field-label')}
                        </label>
                        <input
                          {...register('lastname', { required: t('candidate.field-validation'), maxLength: 80 })}
                          type="text"
                          name="lastname"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.lastname && <span className="text-red-600 text-center">{errors.lastname.message}</span>}
                      </div>
                    </div>
                    <div className="p-2 sm:w-3/4 w-full">
                      <div className="relative">
                        <label htmlFor="street" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.street-field-label')}
                        </label>
                        <input
                          {...register('street', { required: t('candidate.field-validation'), maxLength: 80 })}
                          type="text"
                          name="street"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.street && <span className="text-red-600 text-center">{errors.street.message}</span>}
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/4 w-full">
                      <div className="nr">
                        <label htmlFor="nr" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.number-field-label')}
                        </label>
                        <input
                          {...register('nr', { required: t('candidate.field-validation'), maxLength: 10 })}
                          type="text"
                          name="nr"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.nr && <span className="text-red-600 text-center">{errors.nr.message}</span>}
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="relative">
                        <label htmlFor="postcode" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.zipcode-field-label')}
                        </label>
                        <input
                          {...register('postcode', { required: t('candidate.field-validation'), maxLength: 4 })}
                          type="text"
                          name="postcode"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.postcode && <span className="text-red-600 text-center">{errors.postcode.message}</span>}
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="relative">
                        <label htmlFor="city" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.city-field-label')}
                        </label>
                        <input
                          {...register('city', { required: 'Dit is verplicht', maxLength: 30 })}
                          type="text"
                          name="city"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.city && <span className="text-red-600 text-center">{errors.city.message}</span>}
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="relative">
                        <label htmlFor="name" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.telephone-field-label')}
                        </label>
                        <input
                          {...register('contactPersonPhone', {
                            required: t('candidate.field-validation'),
                            maxLength: 20,
                          })}
                          type="text"
                          name="contactPersonPhone"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.contactPersonPhone && (
                          <span className="text-red-600 text-center">{errors.contactPersonPhone.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="relative">
                        <label htmlFor="email" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.email-field-label')}
                        </label>
                        <input
                          {...register('contactPersonEmail', {
                            required: t('candidate.field-validation'),
                            pattern: /^\S+@\S+$/i,
                          })}
                          type="email"
                          name="contactPersonEmail"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.contactPersonEmail && (
                          <span className="text-red-600 text-center">{errors.contactPersonEmail.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="relative">
                        <label htmlFor="statute" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.statute-field-label')}
                        </label>
                        <select
                          {...register('statute', { required: t('candidate.field-validation') })}
                          name="statute"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        >
                          <option value="student">{t('candidate.statute-field-option-1')}</option>
                          <option value="flexijob">{t('candidate.statute-field-option-2')}</option>
                          <option value="vast">{t('candidate.statute-field-option-3')}</option>
                        </select>
                      </div>
                    </div>
                    <div className="p-2 w-full">
                      <div className="relative">
                        <div className="w-full">
                          <label className="leading-7 text-sm sm:text-lg text-gray-600 pr-2">
                            {t('candidate.job-field-label')}
                          </label>
                          <Controller
                            control={control}
                            name="jobSelection"
                            defaultValue={[]}
                            rules={{ validate: (v) => v && (v.length > 0 || t('candidate.job-field-validation')) }}
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Select
                                inputRef={ref}
                                options={availableJobs && availableJobs}
                                onChange={(val) => onChange(val)}
                                isMulti={true}
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    background: 'rgb(243,244,246,0.5)',
                                  }),
                                }}
                              />
                            )}
                          />
                        </div>
                        {errors.jobSelection && (
                          <span className="text-red-600 text-center">{errors.jobSelection.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="p-2 w-full">
                      <div className="relative">
                        <label htmlFor="comment" className="leading-7 text-sm sm:text-lg text-gray-600">
                          {t('candidate.message-field-label')}
                        </label>
                        <textarea
                          {...register('comment')}
                          name="comment"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                        ></textarea>
                      </div>
                    </div>
                    <div className="p-2 w-full">
                      <div className="relative">
                        <label htmlFor="file"> {t('candidate.cvfile-field-label')}</label>
                        <input {...register('file')} className="p-3 mt-2 mb-2 border-none" type="file" name="file" accept="application/pdf" />
                      </div>
                    </div>

                    <div className="p-2 w-full">
                      <button
                        className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                        type="submit"
                      >
                        {t('candidate.send')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </section>
    </>
  );
}
