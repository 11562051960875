import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const Login = () => {
  const [userData, setUserData] = useState({ username: '', password: '' });
  const [errorMessage, setErrorMessage] = useState({ value: '' });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  const openModal = (jobid) => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('confirmation') && params.get('confirmation') === 'success') {
      setModalMessage('Klant is succesvol geregistreerd!');
      setIsOpen(true);
    }
  }, []);

  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userData.username === '' || userData.password === '') {
      setErrorMessage((prevState) => ({
        value: 'Gebruikersnaam/wachtwoord veld leeg',
      }));
    } else if (userData.username.toLowerCase() === (process.env.BO_USERNAME || 'admin') && userData.password === (process.env.BO_PASSWORD || '123456')) {
      localStorage.setItem('isAuthenticated', 'true');
      window.location.pathname = '/admin';
    } else {
      setErrorMessage((prevState) => ({ value: 'Ongeldig gebruikersnaam/wachtwoord' }));
      return;
    }
  };

  return (
    <div className="text-center md:mt-10 bg-gray-50 max-w-5xl rounded-2xl mx-auto shadow-md">
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="example modal">
        <h3>{modalMessage}</h3>
        <button
          className="flex-1 m-5 text-white bg-[#7761a7] border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          onClick={closeModal}
        >
          Sluit
        </button>
      </Modal>
      <form>
        <div className="p-2 w-1/2 mx-auto">
          <div className="relative">
            <label htmlFor="username" className="leading-7 text-md text-gray-600">
              Gebruikersnaam
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="w-full rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="p-2 w-1/2 mx-auto">
          <div className="relative">
            <label htmlFor="password" className="leading-7 text-md text-gray-600">
              Wachtwoord
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="w-full rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="p-2 w-full">
          <button
            className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            type="submit"
            onClick={handleSubmit}
          >
            Verzend
          </button>
        </div>
        {errorMessage.value}
      </form>
    </div>
  );
};

export default Login;
