import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import RegisterCustomer from './EditCustomer';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const JobList = () => {
  const [jobList, setJobList] = useState({ availableJobs: null });
  const [refreshKey, setRefreshKey] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const openModal = (jobid)=>{
    setIsOpen(true);
    setSelectedJob(jobList.availableJobs.find((el) => el.id === jobid));
  }

  const closeModal = () =>{
    setIsOpen(false);
    setRefreshKey((oldKey) => oldKey + 1);
  }

  useEffect(() => {
    axios.get('/api/jobs').then((res) => {
      setJobList({
        availableJobs: JSON.parse(res.data || []),
      });
    });
  }, [refreshKey]);

  const deleteJob = (id) => {
    axios.delete(`/api/jobs/${id}`).then((res) => {
      setRefreshKey((oldKey) => oldKey + 1);
    });
  };

  const editJob = (id) => {
    //console.log('not implemented');
    openModal(id);
  }

  return (
    <div className="flex flex-col my-auto mt-5 bg-gray-50  rounded-2xl mx-auto shadow-lg">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full">
              <thead className="border-b">
                <tr>
                  <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                    Naam
                  </th>
                  <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                    ContactPersoon
                  </th>
                  <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                    BTW nummer
                  </th>
                  <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-4 text-left"></th>
                  <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-4 text-left"></th>

                </tr>
              </thead>
              <tbody>
                {jobList.availableJobs &&
                  jobList.availableJobs.map((item, index) => {
                    return (
                      <tr className="bg-white border-b" key={index}>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{`${item.companyName} (${item.companyRegion})`}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{`${item.contactPersonName}`}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{`${item.taxid}`}</td>
                        <td className="text-sm text-gray-900 font-light px-2 py-4 whitespace-nowrap">
                          <button onClick={() => editJob(item.id)}>
                            EDIT
                          </button>
                        </td>
                        <td className="text-sm text-gray-900 font-light py-4 whitespace-nowrap">
                          <a href="" onClick={() => deleteJob(item.id)}>
                            DELETE
                          </a>
                        </td>
                        <td className="text-sm text-gray-900 font-light py-4 whitespace-nowrap">
                          <a href={`/api/jobs/download/pdf/${item.id}`}>
                            PDF
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="example modal">
        <RegisterCustomer jobData={selectedJob} onClose={closeModal} />
      </Modal>
    </div>
  );
};

export default JobList;
