import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Stepper from './WizardStepper';
import Step1 from './Step1';
import Step3 from './Step3';
import Step4 from './Step4';
import Step2 from './Step2';
import Step5 from './Step5';

const getComponent = (
  id,
  handleSubmit,
  register,
  errors,
  onSubmitPreview,
  onSubmitContactInformation,
  preview,
  isLoading,
  getValues,
  setValue,
  onSubmitTemplate,
  errorMessage
) => {
  switch (id) {
    case 1:
      return (
        <Step1
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          onSubmit={onSubmitTemplate}
          getValues={getValues}
          setValue={setValue}
        />
      );
    case 2:
      return <Step2 handleSubmit={handleSubmit} register={register} errors={errors} onSubmit={onSubmitPreview} />;
    case 3:
      return <Step3 handleSubmit={handleSubmit} preview={preview} errorMessage={errorMessage} isLoading={isLoading} onSubmit={onSubmitTemplate} />;
    case 4:
      return (
        <Step4 handleSubmit={handleSubmit} register={register} errors={errors} onSubmit={onSubmitContactInformation} />
      );
    case 5:
      return <Step5 isLoading={isLoading} errorMessage={errorMessage} />;
    default:
      break;
  }
};

function RegisterWizard() {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const { t, i18n } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
  } = useForm();

  const onSubmitTemplate = (data) => {
    handleClick('next');
    clearErrors();
  };

  const onSubmitContactInformation = (data) => {
    setIsLoading(true);
    handleClick('next');

    const formData = new FormData();
    formData.set('lang', i18n.language);
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    if (data.file.length > 0) {
      formData.set('file', Array.from(data.file)['0']);
    } else {
      formData.delete('file');
    }

    axios
      .post('/api/jobs', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then((data) => {
        if(data.status !== 200){
          setErrorMsg("Something went wrong, please try again later");
        }else{
          setErrorMsg("");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMsg("Something went wrong, please try again later");
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSubmitPreview = (data) => {
    setIsLoading(true);
    clearErrors();
    handleClick('next');

    const formData = new FormData();
    formData.set('lang', i18n.language);
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    if (data.file.length > 0) {
      formData.set('file', Array.from(data.file)['0']);
    } else {
      formData.delete('file');
    }

    axios
      .post('/api/jobs/preview', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then((data) => {
        if(data.status === 200){
          setPreview('data:image/jpeg;base64,' + data.data.toString('base64'));
          setIsLoading(false);
          setErrorMsg("");
        }else{
          setIsLoading(false);
          console.log(`Catched error`);
          setErrorMsg("Something went wrong, please try again later");
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg("Something went wrong, please try again later");
        setIsLoading(false);
      });
  };

  const stepTitleArray = ['Template', 'Personaliseer', 'Voorbeeld', 'Contactgegevens', 'Voltooi'];

  const handleClick = (clickType = 'next') => {
    let newStep = currentStep;
    clickType === 'previous' ? newStep-- : newStep++;

    // Check if steps are within the boundary
    if (newStep > 0 && newStep <= stepTitleArray.length) {
      setCurrentStep(newStep);
    }
  };

  return (
    <section className="text-gray-600 body-font relative  rounded-3xl p-4" id="register-customer" data-aos="zoom-in-up">
      <div className="container horizontal mt-5 sm:mt-14 sm:mb-12 mx-auto">
        <Stepper steps={stepTitleArray} currentStepNumber={currentStep} />
      </div>
      <div className="container px-5 py-5 mx-auto">
        {getComponent(
          currentStep,
          handleSubmit,
          register,
          errors,
          onSubmitPreview,
          onSubmitContactInformation,
          preview,
          isLoading,
          getValues,
          setValue,
          onSubmitTemplate,
          errorMsg
        )}
      </div>
      {currentStep <5 && ( 
      <div className="container flex justify-around mx-auto">
      {currentStep > 1 && (
        <button
          onClick={() => handleClick('previous')}
          className="btn-primary transition duration-300 ease-in-out bg-[#7761a7] hover:bg-blue-900 text-white font-normal py-2 px-4 mr-1 rounded"
        >
          {t('registration.button-previous')}
        </button>
      )}

      <button
        className="btn-primary transition duration-300 ease-in-out  bg-[#7761a7] hover:bg-blue-900 text-white  font-normal py-2 px-4 rounded"
        type="submit"
        form="content-form"
      >
        {currentStep === 4 ? t('registration.button-finish') : t('registration.button-next') }
      </button>
    </div>

      )}

    </section>
  );
}
export default RegisterWizard;
