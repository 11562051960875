import React from 'react';
import { useTranslation } from 'react-i18next';

import template1 from '../img/template1.png';
import template2 from '../img/template2.png';
import template3 from '../img/template3.png';

import template1fr from '../img/template-1-fr.jpeg';
import template2fr from '../img/template-2-fr.jpeg';
import template3fr from '../img/template-3-fr.jpeg';

export default function Step1(props) {
  const { t, i18n } = useTranslation();
  return (
    <form onSubmit={props.handleSubmit(props.onSubmit)} id="content-form">
      <div className="flex flex-col text-center w-full">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-6 text-gray-900">{t('registration.step1.title')}</h1>
        <div className="p-2 w-full sm:flex flex-row">
          <div className="flex-1 py-2 sm:py-0">
            <label htmlFor="template1" className="cursor-pointer group">
              <input
                {...props.register('templateChoice')}
                type="radio"
                name="templateChoice"
                value="1"
                id="template1"
                className="sr-only peer"
              />
              <img
                src={i18n.language === 'fr' ? template1fr : template1}
                alt="template1"
                className="w-4/5 m-auto group-hover:border-8 peer-checked:border-8"
              />
            </label>
          </div>
          <div className="flex-1 py-2 sm:py-0">
            <label htmlFor="template2" className="cursor-pointer group">
              <input
                {...props.register('templateChoice')}
                type="radio"
                name="templateChoice"
                defaultChecked="true"
                value="2"
                id="template2"
                className="sr-only peer"
              />
              <img
                src={i18n.language === 'fr' ? template2fr : template2}
                alt="template2"
                className="w-4/5 m-auto group-hover:border-8 peer-checked:border-8"
              />
            </label>
          </div>
          <div className="flex-1 py-2 sm:py-0">
            <label htmlFor="template3" className="cursor-pointer group">
              <input
                {...props.register('templateChoice')}
                type="radio"
                name="templateChoice"
                value="3"
                id="template3"
                className="sr-only peer"
              />
              <img
                src={i18n.language === 'fr' ? template3fr : template3}
                alt="template3"
                className="w-4/5 m-auto group-hover:border-8 transition duration-500 ease-in-out peer-checked:border-8"
              />
            </label>
          </div>
        </div>
        <h1 className="sm:text-3xl text-2xl font-medium title-font my-6 text-gray-900">
          {t('registration.step1.subtitle')}
        </h1>

        <label htmlFor="file" className="cursor-pointer group">
          <input
            {...props.register('templateChoice')}
            type="radio"
            name="templateChoice"
            value="0"
            id="file"
            className="sr-only peer"
          />
          <div className="relative peer-checked:border-8 group-hover:border-8">
            <div className="my-auto">
              <input
                {...props.register('file', {
                  onChange: () => {
                    props.setValue('templateChoice', 0);
                    document.getElementById('file').checked = true;
                  },
                  validate: {
                    required: (v) => props.getValues('templateChoice') !== '0' || v.length === 1 || t('registration.step1.validation-image-mandatory'),
                    filesize: (v) => v.length === 0 || v[0].size <= 2000000 || t('registration.step1.validation-image-size'),
                    format: (v) => v.length === 0 || (v[0].type === 'image/jpeg' || v[0].type === 'image/jpg' || v[0].type === 'image/png') || t('registration.step1.validation-image-format')
                  } 
                })}
                className="p-3 mt-2 mb-2 border-none"
                type="file"
                name="file"
                accept="image/*"
              />
              {props.errors.file &&  <div className="w-full"><span className="text-red-600 text-center">{props.errors.file.message}</span></div>}
            </div>
            <ul>
              <li><small>{t('registration.step1.image-instruction-1')}</small></li>
              <li><small>{t('registration.step1.image-instruction-2')}</small></li>
            </ul>
          </div>
        </label>
      </div>
    </form>
  );
}
