import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Step2(props) {
  const { t } = useTranslation();
  return (
    <form onSubmit={props.handleSubmit(props.onSubmit)} id="content-form">
      <div className="lg:w-2/3 md:w-2/3 mx-auto">
        <div className="flex flex-wrap -m-2">
          <h3 className="w-full sm:text-2xl text-xl font-medium title-font mb-4 text-gray-900">
            {t('registration.step2.title')}
          </h3>
          <div className="p-2 sm:w-1/2">
            <div className="relative">
              <label htmlFor="companyName" className="leading-7 text-sm text-gray-600">
                {t('registration.step2.name-field-label')}
              </label>
              <input
                {...props.register('companyName', {
                  required: t('registration.step2.field-validation'),
                  maxLength: 40,
                })}
                type="text"
                name="companyName"
                className="w-full bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              {props.errors.companyName && <span className="text-red-600">{props.errors.companyName.message}</span>}
            </div>
          </div>
          <div className="p-2 sm:w-1/2">
            <div className="relative">
              <label htmlFor="companyRegion" className="leading-7 text-sm text-gray-600">
                {t('registration.step2.region-field-label')}
              </label>
              <input
                {...props.register('companyRegion', {
                  required: t('registration.step2.field-validation'),
                  maxLength: 40,
                })}
                type="text"
                name="companyRegion"
                className="w-full bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              {props.errors.companyRegion && <span className="text-red-600">{props.errors.companyRegion.message}</span>}
            </div>
          </div>
          <div className="p-2 w-full">
            <div className="relative">
              <div className="w-full">
                <label htmlFor="candidateTypes" className="leading-7 text-lg text-gray-600">
                  {t('registration.step2.candidate-field-label')}
                </label>
                <div className="flex flex-wrap">
                  <div className="w-full sm:w-1/3">
                    <input
                      {...props.register('candidateTypes', {
                        required: t('registration.step2.candidate-field-option-validation'),
                      })}
                      className="p-3 mt-2 mb-2 ml-3 border-none"
                      type="checkbox"
                      name="candidateTypes"
                      value="flexijob"
                    />{' '}
                    {t('registration.step2.candidate-field-option-1')}
                  </div>
                  <div className="w-full sm:w-1/3">
                    <input
                      {...props.register('candidateTypes', {
                        required: t('registration.step2.candidate-field-option-validation'),
                      })}
                      className="p-3  mt-2 mb-2 ml-3 border-none"
                      type="checkbox"
                      name="candidateTypes"
                      value="student"
                    />{' '}
                    {t('registration.step2.candidate-field-option-2')}
                  </div>
                  <div className="w-full sm:w-1/3">
                    <input
                      {...props.register('candidateTypes', {
                        required: t('registration.step2.candidate-field-option-validation'),
                      })}
                      className="p-3  mt-2 mb-2 ml-3 border-none"
                      type="checkbox"
                      name="candidateTypes"
                      value="extra"
                    />{' '}
                    {t('registration.step2.candidate-field-option-3')}
                  </div>
                </div>
              </div>
              {props.errors.candidateTypes && (
                <span className="text-red-600">{props.errors.candidateTypes.message}</span>
              )}
            </div>
          </div>
          <div className="p-2 w-full">
            <div className="relative">
              <div className="w-full">
                <label htmlFor="jobPeriod" className="leading-7 text-lg text-gray-600">
                  {t('registration.step2.period-field-label')}
                </label>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/3 sm:w-1/2">
                    <input
                      {...props.register('jobPeriod')}
                      className="p-3 mt-2 mb-2 ml-3 border-none"
                      type="checkbox"
                      name="jobPeriod"
                      value="voorjaar"
                    />{' '}
                    {t('registration.step2.period-field-option-1')}
                  </div>
                  <div className="w-full md:w-1/3 sm:w-1/2">
                    <input
                      {...props.register('jobPeriod')}
                      className="p-3  mt-2 mb-2 ml-3 border-none"
                      type="checkbox"
                      name="jobPeriod"
                      value="zomer"
                    />{' '}
                    {t('registration.step2.period-field-option-2')}
                  </div>
                  <div className="w-full lg:w-1/3 sm:w-1/2">
                    <input
                      {...props.register('jobPeriod')}
                      className="p-3  mt-2 mb-2 ml-3 border-none"
                      type="checkbox"
                      name="jobPeriod"
                      value="weekends"
                    />{' '}
                    {t('registration.step2.period-field-option-3')}
                  </div>
                </div>
              </div>
              {props.errors.candidateTypes && (
                <span className="text-red-600">{props.errors.candidateTypes.message}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
